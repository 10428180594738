(function () {
  'use strict';

  const send2bridge = function (frm, content, print_type) {
    const printService = new frappe.silent_print.WebSocketPrinter({
      onConnect: function () {
        frappe.silent_print.sendRaw(printService, content, print_type);
      }
    });
  }

  frappe.ui.form.on("Stock Entry", "refresh", function (frm) {
    frm.add_custom_button(__("Print Item Labels"), function () {
      const spaceBetween = 320;

      let zpl = '^XA';
      for (let i = 0; i < frm.doc.items.length; i++) {
        const item = frm.doc.items[i];
        // Label Field
        zpl += '^FO30,' + (40 + i * spaceBetween); // Field Origin [x, y]
        zpl += '^A0,36,20'; // Font [name, height, width]
        zpl += '^FD' + item.item_name; // Field Data
        zpl += '^FS'; // Field Stop

        // Barcode Field
        zpl += '^FO30,' + (80 + i * spaceBetween); // Field Origin
        zpl += '^BY4'; // Barcode [bar width]
        zpl += '^B3N,,200'; // Barcode [orientation, addCheckDigit, height, showHumanReadableText, showTextAbove]
        zpl += '^FD' + item.item_code; // Field Data
        zpl += '^FS'; // Field Stop
      }
      zpl += '^XZ'

      send2bridge(frm, zpl, 'THERMAL');
    });
  });
}());
