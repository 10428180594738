import './ui'
import './navbar'

$(() => {
  let typedString = '';
  const startTag = '%ASG%!';
  const endTag = '%ASG%!';

  // Function to handle the global keypress event
  function handleKeyPress(event) {
    // Append the typed character to the string
    typedString += event.key;

    // Check if the string contains both start and end tags
    const startIndex = typedString.indexOf(startTag);
    const endIndex = typedString.indexOf(endTag, startIndex + startTag.length);

    if (startIndex !== -1 && endIndex !== -1) {
      // Extract the substring between the tags
      const capturedString = typedString.substring(startIndex + startTag.length, endIndex);
      console.log('Captured String:', capturedString);

      // Perform the desired action with the captured string
      const [doctype, docname] = capturedString.split('$');
      
      // Redirect to the desired document
      frappe.set_route('Form', doctype, docname);

      // Clear the typedString after capturing
      typedString = '';
    }

    // Limit the size of the typedString to avoid memory issues
    if (typedString.length > 1000) {
      typedString = typedString.slice(-1000);
    }
  }

  // Add the event listener to the document
  document.addEventListener('keypress', handleKeyPress);
})