frappe.provide('frappe.ui.form');

frappe.ui.form.CustomerQuickEntryForm = class CustomerQuickEntryForm extends frappe.ui.form.CustomerQuickEntryForm {
  render_dialog() {
    super.render_dialog();
    
    setTimeout(() => {
      const placePickerInput = $('.modal.show #place-picker');
      const addressLine1Input = $('.modal.show input[data-fieldname="address_line1"]');
      const zipInput = $('.modal.show input[data-fieldname="pincode"]');
      const cityInput = $('.modal.show input[data-fieldname="city"]');
      const countryInput = $('.modal.show input[data-fieldname="country"]');

      placePickerInput.on("focus", () => {
        placePickerInput.select();
      });

      placePickerInput.on("keydown", (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      });
      
      const autocomplete = new google.maps.places.Autocomplete(placePickerInput.get(0), { fields: ['address_components', 'geometry'] });
      autocomplete.addListener('place_changed', this._handlePlaceChangedEvent(autocomplete, addressLine1Input, zipInput, cityInput, countryInput));

      const customerNameInput = $('.modal.show input[data-fieldname="customer_name"]');
      const firstNameInput = $('.modal.show input[data-fieldname="first_name"]');
      const lastNameInput = $('.modal.show input[data-fieldname="last_name"]');
      
      customerNameInput.on("keyup", this._splitCustomerName(customerNameInput, firstNameInput, lastNameInput));
      customerNameInput.on("blur", this._splitCustomerName(customerNameInput, firstNameInput, lastNameInput));
    }, 500);
  }

  _handlePlaceChangedEvent(autocomplete, addressLine1Input, zipInput, cityInput, countryInput) {
    return async () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        return;
      }

      const addressComponents = {};
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        switch (addressType) {
          case 'street_number':
            addressComponents.streetNumber = place.address_components[i].long_name;
            break;
          case 'route':
            addressComponents.street = place.address_components[i].long_name;
            break;
          case 'locality':
            addressComponents.city = place.address_components[i].long_name;
            break;
          case 'postal_code':
            addressComponents.zip = place.address_components[i].long_name;
            break;
          case 'country':
            addressComponents.country = place.address_components[i].short_name;
            break;
        }
      }

      const { message: country } = await frappe.db.get_value('Country', { code: addressComponents.country }, 'name');

      cur_dialog.fields_dict.address_line1.set_value(`${addressComponents.street} ${addressComponents.streetNumber}` || "");
      cur_dialog.fields_dict.pincode.set_value(addressComponents.zip || "");
      cur_dialog.fields_dict.city.set_value(addressComponents.city || "");
      cur_dialog.fields_dict.country.set_value(country.name || "");
      
      addressLine1Input.val(`${addressComponents.street} ${addressComponents.streetNumber}`);
      zipInput.val(addressComponents.zip);
      cityInput.val(addressComponents.city);
      countryInput.val(country.name);
    };
  }

  _splitCustomerName(customerNameInput, firstNameInput, lastNameInput) {
    return () => {
      const firstName = customerNameInput.val().trim().split(' ').slice(0, -1).join(' ');
      const lastName = customerNameInput.val().trim().split(' ').slice(-1).join(' ');

      cur_dialog.fields_dict.first_name.set_value(firstName || "");
      cur_dialog.fields_dict.last_name.set_value(lastName || "");
      
      firstNameInput.val(firstName);
      lastNameInput.val(lastName);
    };
  }

  get_variant_fields() {
    return [{
      fieldtype: "Section Break",
      label: __("Primary Contact Details"),
      collapsible: 0
    },
      {
        label: __("Salutation"),
        fieldname: "salutation",
        fieldtype: "Link",
        options: "Salutation",
      },
      {
        label: __("First Name"),
        fieldname: "first_name",
        //depends_on: "eval:doc.customer_type === \"Company\"",
        mandatory_depends_on: "eval:doc.customer_type !== \"Company\"",
        fieldtype: "Data",
      },
      {
        label: __("Last Name"),
        //depends_on: "eval:doc.customer_type === \"Company\"",
        mandatory_depends_on: "eval:doc.customer_type !== \"Company\"",
        fieldname: "last_name",
        fieldtype: "Data",
      },
      {
        label: __("Department"),
        fieldname: "department",
        depends_on: "eval:doc.customer_type === \"Company\"",
        fieldtype: "Data",
      },
      {
        fieldtype: "Column Break"
      },
      {
        label: __("Email Id"),
        mandatory_depends_on: "eval:!doc.mobile_number",
        fieldname: "email_address",
        fieldtype: "Data",
        options: "Email",
      },
      {
        label: __("Mobile Number"),
        mandatory_depends_on: "eval:!doc.email_address",
        fieldname: "mobile_number",
        fieldtype: "Data"
      },
      {
        fieldtype: "Section Break",
        label: __("Primary Address Details"),
        collapsible: 0
      },
      {
        label: __("Primary Address Details"),
        options: `<div class="form-group">
                    <div class="clearfix">
                        <label class="control-label" style="padding-right: 0px;">${__("Address Search")}</label>
                    </div>
                    <div class="control-input-wrapper">
                        <div class="control-input">
                            <div class="link-field ui-front" style="position: relative;">
                            <div class="awesomplete">
                                <input type="text" class="input-with-feedback form-control" id="place-picker" placeholder="">
                            </div>
                        </div>
                    </div>
                  </div>`,
        fieldtype: "HTML",
      },
      {
        fieldtype: "Section Break",
        hide_border: 1,
      },
      {
        label: __("Address Line 1"),
        fieldname: "address_line1",
        fieldtype: "Data"
      },
      {
        label: __("Address Line 2"),
        fieldname: "address_line2",
        fieldtype: "Data"
      },
      {
        label: __("ZIP Code"),
        fieldname: "pincode",
        fieldtype: "Data"
      },
      {
        fieldtype: "Column Break"
      },
      {
        label: __("City"),
        fieldname: "city",
        fieldtype: "Data"
      },
      {
        label: __("State"),
        fieldname: "state",
        fieldtype: "Data",
        hidden: 1
      },
      {
        label: __("Country"),
        fieldname: "country",
        fieldtype: "Link",
        options: "Country",
        default: "Germany"
      },
      {
        label: __("Customer POS Id"),
        fieldname: "customer_pos_id",
        fieldtype: "Data",
        hidden: 1
      }];
  }
};
