$(document).ready(() => {
  check_inbox_permission();
});

function check_inbox_permission() {
  frappe.call({
    method: 'cr_wms.coderockets_warehouse_management.utils.util.check_permission',
    args: {
      doctype: 'Communication',
    },
    async: false,
    callback: function(result) {
      console.log(result)
      if(result.message) {
        add_inbox_icon();
      }
    }
  });
}

function add_inbox_icon() {
  if (!$('.navbar').find('a.inbox-icon').length) {
    const inbox_icon_html = `
      <li class="nav-item">
        <a class="nav-link inbox-icon text-muted"">
          <svg class="icon icon-md"><use href="#icon-mail"></use></svg>
        </a>
      </li> `;
    if ('desk' in frappe) {
      $(inbox_icon_html).insertBefore('.nav-item.dropdown.dropdown-notifications');
      /*$(`<span style="color: red">DEV</span>`).insertBefore('.input-group.search-bar'); TODO*/

      $('.inbox-icon').on("click", (e) => {
        e.preventDefault();
        frappe.set_route('List/Communication/inbox');
      });
    }
  }
}